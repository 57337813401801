@import "../../assets/styles/utils";

.pages,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include res(margin-top,.5rem,(md:20px));
  a,li{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f2f2f3;
    transition: $anime-duration $anime-bezier;
    cursor: pointer;
    transition: all .4s;
    @include res(margin-bottom,.05rem,(md:5px));
    @include res(margin-left,.1rem,(md:5px));
    @include res(margin-right,.1rem,(md:5px));
    @include res(font-size, .18rem,(md:16px));
    @include res(width,.5rem,(md:40px));
    @include res(height,.5rem,(md:40px));
    &:hover{
      background-color: #ebebeb;
      color: $color-main;
    }
    &.active,&.xl-active{
      background-color: $color-bg-dark;
      color: #fff;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
  }
  .page_jump_text{
    @include res(margin-left,.1rem,(md:10px));
    @include res(margin-right,.1rem,(md:10px));
  }
  .page_jump_input{
    @include res(width,.6rem,(md:60px));
    @include res(height,.3rem,(md:30px));
  }
  .page_jump_btn{
    background: none;
    border: none;
    cursor: pointer;
    transition: all;
    &:hover{
      color: $color-main;
    }
  }
}