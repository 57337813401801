@import "../../assets/styles/utils";

footer{
  .footer1{
    background-color: $color-bg-dark;
    color: #fff;
    @include res(padding-left,8.3%, 5 / 8.3);
    @include res(padding-right,8.3%, 5 / 8.3);
    @include res(padding-top,.5rem);
    @include res(padding-bottom,.5rem);
    .cont{
      .footer1-title{
        align-items: center;
        justify-content: center;
        @include res(display,flex);
        @include res(margin-bottom,.4rem);
        &::before,&::after{
          content: '';
          display: block;
          flex-grow: 1;
          height: .01rem;
          background-color: rgba(255,255,255,.2);
        }
        img{
          display: block;
          height: auto;
          @include res(width,1.65rem,(md:2.2rem));
          @include res(margin-left,.15rem);
          @include res(margin-right,.15rem);
        }
      }
      .footer1-nav{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include res(justify-content,space-around,(xs:flex-start));
        li{
          text-align: center;
          @include res(width,20%,(xs:33.33%));
          @include res(line-height,.2rem);
          a{
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            @include res(padding,.2rem);
            &::before{
              content: '';
              display: inline-block;
              height: .02rem;
              background-color: #fff;
              margin-right: .05rem;
              transition: $anime-duration $anime-bezier;
              width: 0;
            }
            &:hover::before{
              width: .1rem;
            }
          }
        }
      }
    }
  }
  .footer2{
    align-items: stretch;
    justify-content: flex-start;
    @include res(display,flex,(xs:block));
    .item{
      position: relative;
      background-color: #000;
      overflow: hidden;
      @include res(height,1.65rem,(md:2.2rem));
      @include res(width,33.33%,(xs:100%));
      &:hover{
        b{
          transform: scale(1.1);
        }
      }
      a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      b{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .6;
        transition: $anime-duration $anime-bezier;
      }
      img{
        position: relative;
        z-index: 1;
        height: auto;
        @include res(width,.3rem,(md:30px));
        @include res(margin-right,.15rem);
      }
      span{
        position: relative;
        z-index: 1;
        color: #fff;
        font-weight: bold;
        @include res(font-size,18px, 16 / 18);
      }
    }
  }
  .footer3{
    @include res(padding-left,8.3%, 5 / 8.3);
    @include res(padding-right,8.3%, 5 / 8.3);
    .btn-link{
      align-items: center;
      justify-content: space-between;
      border-bottom: .01rem solid #c0c0c0;
      @include res(display,flex,(xs:block));
      @include res(padding-top,.3rem);
      @include res(padding-bottom,.3rem);
      .left{
        a{
          color: #333;
          transition: $anime-duration $anime-bezier;
          &:hover{
            color: $color-main;
          }
          &:not(:first-child){
            @include res(margin-left,.03rem);
            &::before{
              content: '|';
              @include res(margin-right,.05rem);
            }
          }
          &.site-map{
            @include res(display,null,(md:none));
          }
        }
      }
      .right{
        align-items: center;
        @include res(justify-content,flex-start,(xs:center));
        @include res(display,flex);
        @include res(margin-top,0,(xs:.1rem));
        .weixin{
          position: relative;
          @include res(margin-right,.2rem);
          &:hover{
            .iconfont{
              color: #0ECA19;
            }
            .wx-img{
              opacity: 1;
              visibility: visible;
            }
          }
          .iconfont{
            line-height: 1;
            color: #000;
            cursor: pointer;
            transition: $anime-duration $anime-bezier;
            @include res(font-size,.4rem,(md:32px));
          }
          .wx-img{
            position: absolute;
            bottom: 120%;
            left: 50%;
            background-color: #fff;
            transform: translateX(-50%);
            transition: $anime-duration $anime-bezier;
            opacity: 0;
            visibility: hidden;
            z-index: 2;
            padding: .05rem;
            border-radius: .05rem;
            box-shadow: 0 0 .1rem rgba(0,0,0,.1);
            &::after{
              content: '';
              display: block;
              border: .08rem solid;
              border-color: rgba(0,0,0,.04) transparent transparent transparent;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
            }
            img{
              height: auto;
              display: block;
              @include res(width,1.08rem,(md:100px));
            }
          }
        }
        .back-top{
          cursor: pointer;
          &:hover{
            .iconfont{
              color: $color-main;
              transform: translateY(-.05rem);
            }
          }
          .iconfont{
            line-height: 1;
            color: #000;
            transition: $anime-duration $anime-bezier;
            display: inline-block;
            @include res(font-size,.34rem,(md:30px));
          }
        }
      }
    }
    .copyright{
      text-align: center;
      color: #666;
      @include res(font-size,14px, 12 / 14);
      @include res(padding-top,.3rem);
      @include res(padding-bottom,.3rem);
      a{
        display: inline-block;
        transition: $anime-duration $anime-bezier;
        &:hover{
          color: #000;
        }
      }
    }
  }
}

.fixed-contact{
  .fixed-icon{
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px .04rem .12rem;
    position: fixed;
    background: rgb(255, 255, 255);
    color: rgb(66, 77, 87);
    z-index: 10;
    cursor: pointer;
    transition: all .25s ease-in-out;
    @include res(width,.6rem,(md:60px));
    @include res(height,.6rem,(md:60px));
    @include res(right,.15rem,(md:15px));
    @include res(bottom,.2rem,(md:20px));
    &.active{
        transform: translateY(150%);
    }
    .blue-icon,.white-icon,.dian{
        position: absolute;
        transform-origin: center;
        line-height: 1.2;
    }
    .blue-icon{
        z-index: 1;
        transition: all .25s;
        @include res(top,.1rem,(md:10px));
        @include res(left,.13rem,(md:13px));
        .iconfont{
            color: $color-main;
            @include res(font-size,.34rem,(md:34px));
        }
    }
    .white-icon{
        z-index: 2;
        transition: all .15s;
        @include res(top,.15rem,(md:15px));
        @include res(left,.18rem,(md:18px));
        .iconfont{
            color: #fff;
            @include res(font-size,.24rem,(md:24px));
        }
    }
    .dian{
        z-index: 3;
        top: 45%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        display: flex;
        align-items: center;
        justify-content: center;
        b{
            display: block;
            border-radius: 50%;
            background-color: #fff;
            @include res(width,.04rem,(md:4px));
            @include res(height,.04rem,(md:4px));
            @include res(margin,0 .02rem,(md:0 2px));
        }
    }
    &:hover{
        .blue-icon{
            transform: scale(1.1);
        }
        .white-icon{
            transform: scale(0);
        }
        .dian{
            b{
                animation: topdian .2s 1 linear;
                &:nth-child(1){
                    animation-delay: .05s;
                }
                &:nth-child(2){
                    animation-delay: .15s;
                }
                &:nth-child(3){
                    animation-delay: .25s;
                }
            }
        }
    }
  }

  .fixed-form{
    box-shadow: rgba(0, 0, 0, 0.3) 0px .04rem .12rem;
    overflow: hidden;
    background: #fff;
    position: fixed;
    z-index: 11;
    transition: all .25s ease-in-out;
    transform-origin: bottom right;
    transform: scale(0);
    @include res(padding, .35rem);
    @include res(width,6rem,(md:600px,xs:calc(100% - 20px)));
    @include res(right,.15rem,(xs:10px));
    @include res(bottom,.2rem,(xs:10px));
    &.active{
      transform: scale(1);
    }
    .form-title{
      color: #000;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include res(font-size,.24rem,(sm:20px));
      @include res(margin-bottom, .3rem);
      .close-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include res(width,.5rem);
        @include res(height,.5rem);
        &:hover{
          .iconfont{
            transform: rotate(90deg);
          }
        }
        .iconfont{
          transition: all .4s;
          display: inline-block;
          @include res(font-size, .2rem,(md:20px));
        }
      }
    }
    .form-desc{
      @include res(line-height, 1.77, 1.6 / 1.77);
      @include res(font-size, .18rem,(md:14px));
      @include res(margin-bottom, .3rem);
    }
    .form{
      overflow-x: hidden;
      overflow-y: auto;
      @include res(max-height,60vh);
      form{
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        @include res(display,flex,(xs:block));
        .form-item{
          position: relative;
          overflow: hidden;
          z-index: 1;
          border-bottom-style: solid;
          border-bottom-color: #000;
          @include res(border-bottom-width,.01rem);
          @include res(width,100%);
          @include res(padding-top,.1rem);
          &.active{
            label{
              @include res(font-size, .14rem,(md:14px));
              @include res(top,-.05rem,(md:-3px));
            }
          }
          label{
            position: absolute;
            left: .02rem;
            z-index: -1;
            transition: $anime-duration $anime-bezier;
            color: #585858;
            @include res(top, .1rem,(md:10px));
            @include res(font-size, .18rem,(md:16px));
            @include res(line-height,.4rem);
            span{
              color: red;
            }
          }
          input{
            width: 100%;
            margin-top: .1rem;
            background: none;
            border: none;
            outline: none;
            @include res(font-size, .18rem,(md:16px));
            @include res(height,.4rem,(md:35px));
          }
          textarea{
            width: 100%;
            background: none;
            border: none;
            outline: none;
            @include res(font-size, .18rem,(md:16px));
            @include res(margin-top,.23rem);
          }
        }
        .form-btn{
          width: 100%;
          @include res(text-align,left,(xs:center));
          @include res(margin-top,.3rem);
          button{
            color: #fff;
            background-color:$color-bg-dark;
            cursor: pointer;
            transition: all .3s;
            width: 100%;
            box-sizing: border-box;
            border: none;
            @include res(padding-left,.85rem);
            @include res(padding-right,.85rem);
            @include res(padding-top,.2rem);
            @include res(padding-bottom,.2rem);
            @include res(font-size,.18rem,(md:16px));
            &:hover{
              background-color:$color-main-light;
            }
          }
        }
      }
    }
  }
}

@keyframes topdian{
  0%{
      transform: translateY(0);
  }
  50%{
      transform: translateY(-3px);
  }
  100%{
      transform: translateY(0);
  }
}