@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/dist/css/swiper.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "roboto","PingFang SC","Microsoft YaHei";
  line-height: 1.8;
  position: relative;
  color: $color-text;
  background-color: #fff;
  vertical-align: middle;
  font-size: 12px;
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size, .16rem,(md:16px));
}


//主体内容部分宽度
.container {
  width: 100%;
  box-sizing: border-box;
  // max-width: 1200px;
  // @include res(box-sizing,content-box,(mmd:border-box));
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
}



// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  display: block;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: $anime-duration $anime-bezier;
  }
}

// 公共按钮样式
.common-btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  border: .01rem solid #000;
  padding: .02rem;
  &:hover{
    .icon{
      &::after{
        transform: scale(1);
      }
      .iconfont{
        color: #fff;
      }
    }
    .t{
      @include res(width,1.15rem, (md:81px));
    }
  }
  .icon{
    position: relative;
    z-index: 1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $anime-duration $anime-bezier;
    @include res(width,.5rem,(md:30px));
    @include res(height,.5rem,(md:30px));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: .5rem;
      background-color: $color-main;
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center;
      z-index: -1;
      transition: $anime-duration $anime-bezier;
      @include res(transform,scale(0),(md:scale(1)));
    }
    .iconfont{
      transition: $anime-duration $anime-bezier;
      @include res(color,#000,(md:#fff));
      @include res(font-size,.22rem,(md:20px));
    }
  }
  .t{
    overflow: hidden;
    white-space: nowrap;
    color: #000;
    transition: $anime-duration $anime-bezier;
    @include res(width,0,(md:auto));
    @include res(padding-top,.03rem);
    @include res(font-size, .18rem,(md:14px));
    span{
      display: block;
      @include res(padding-left,.18rem,(md:10px));
      @include res(padding-right,.25rem,(md:15px));
    }
  }
  &.white{
    border: .01rem solid #fff;
    &:hover{
      .icon{
        .iconfont{
          color: #000;
        }
      }
    }
    .icon{
      &::after{
        background-color: #fff;
      }
      .iconfont{
        @include res(color,#fff,(md:#000));
      }
    }
    .t{
      color: #fff;
    }
  }
}


// 内页布局公共样式
.ab-pl{
  @include res(padding-left,24%,(sm:7.8%,xs:5.5%));
}
.ny-pd{
  @include res(padding-left,24%,(sm:7.8%,xs:5.5%));
  @include res(padding-right,7.8%,(sm:7.8%,xs:5.5%));
}

.ny-position{
  position: relative;
  @include res(margin-bottom,1.5rem,(md:30px));
  // @include res(margin-top,.2rem);
  .ny-sub-nav{
    z-index: 10;
    @include res(display,block,(sm:none));
    @include res(position,absolute,(sm:static));
    @include res(margin-bottom,null,(sm:30px));
    @include res(width,1.7rem,(md:170px,sm:100%));
    @include res(top,0,(sm:auto));
    @include res(bottom,auto,(sm:100%));
    @include res(right,81.25%,(sm:0));
    @include res(border-top,.02rem solid #000,(md:2px solid #000,sm:none));
    @include res(border-bottom,.02rem solid #000,(md:2px solid #000,sm:none));
    &.fixed{
      top: 200px;
      transform: translateY(0%);
      @include res(position,fixed,(sm:static));
    }
    &.abs{
      top: auto;
      bottom: 0;
      transform: translateY(0%);
      @include res(position,absolute,(sm:static));
    }
    ul{
      align-items: stretch;
      justify-content: flex-start;
      white-space: nowrap;
      @include res(overflow-x,hidden,(sm:auto));
      @include res(overflow-y,null,(sm:hidden));
      li{
        flex-grow: 1;
        &:not(:last-child){
          // @include res(border-bottom,.01rem solid #464a50,(md:1px solid #464a50,sm:none));
        }
        &.active{
          .nav-title{
            color: #000;
            .iconfont::before{
              transform: rotate(-90deg);
            }
          }
        }
        &.down{
          .nav-title{
            .iconfont::before{
              transform: rotate(-90deg);
            }
          }
        }
        .nav-title{
          align-items: stretch;
          justify-content: space-between;
          @include res(text-align,left,(sm:center));
          @include res(display,flex);
          a{
            flex-grow: 1;
            transform: $anime-duration $anime-bezier;
            @include res(padding-top,.05rem,(md:5px));
            @include res(padding-bottom,.05rem,(md:5px));
            @include res(font-size,.22rem,(md:17px));
            @include res(padding-left,null,(sm:10px));
            @include res(padding-right,null,(sm:10px));
            &:hover{
              color: #000;
            }
          }
          .iconfont{
            cursor: pointer;
            align-items: center;
            @include res(display,flex,(sm:none));
            @include res(padding-left,.15rem,(md:15px));
            @include res(font-size,.2rem,(md:20px));
            &:hover{
              color: #000;
              &::before{
                transform: rotate(-90deg);
              }
            }
            &::before{
              transform: rotate(90deg);
              transition: $anime-duration $anime-bezier;
            }
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left,.2rem,(md:10px));
          @include res(padding-bottom,.1rem,(md:10px));
          a{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: all $anime-duration;
            @include res(font-size,.18rem,(md:16px));
            // &::before{
            //   content: '';
            //   display: block;
            //   width: 0px;
            //   background-color: #000;
            //   transition: $anime-duration $anime-bezier;
            //   @include res(height,.03rem,(md:3px));
            // }
            &:hover{
              color: #000;
              font-weight: bold;
            }
            &.active,&.on{
              color: #000;
              font-weight: bold;
              // &::before{
              //   @include res(width,.1rem,(md:10px));
              //   @include res(margin-right,.05rem,(md:5px));
              // }
            }
          }
        }
      }
    }
  }
}

.ny-title{
  display: inline-block;
  border-radius: .5rem;
  color: #000;
  @include res(border,.01rem solid #000,(md:1px solid #000));
  @include res(font-size,.14rem,(md:14px));
  @include res(line-height,.34rem,(md:34px));
  @include res(padding-left,.25rem,(md:15px));
  @include res(padding-right,.25rem,(md:15px));
}

// 产品列表公共样式
.pro-list-item{
  background-color: #ebebeb;
  &:hover{
    .item-img{
      .hiddden-cont{
        opacity: 1;
        visibility: visible;
        ul{
          li{
            opacity: 1;
            transform: translateY(0);
            &:nth-child(1){
              transition-delay: .3s;
            }
            &:nth-child(2){
              transition-delay: .4s;
            }
            &:nth-child(3){
              transition-delay: .5s;
            }
            &:nth-child(4){
              transition-delay: .6s;
            }
            &:nth-child(5){
              transition-delay: .7s;
            }
          }
          .common-btn{
            opacity: 1;
            transform: translateY(0);
            transition-delay: .8s;
          }
        }
      }
    }
  }
  .item-img{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(height,4rem,(md:300px));
    .show-cont{
      height: 80%;
      padding-top: 5%;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 80%;
      &::after{
        content: '';
        display: block;
        width: 100%;
        background: url(../../assets/images/pro_shadow1.png) no-repeat center bottom;
        background-size: auto 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform: translateY(40%);
        @include res(height,.27rem,(md:27px));
      }
      img{
        display: block;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .hiddden-cont{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: $anime-duration $anime-bezier;
      opacity: 0;
      visibility: hidden;
      @include res(display,block,(md:none));
      ul{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        li{
          color: #fff;
          font-weight: bold;
          opacity: 0;
          transition: all .3s;
          @include res(transform,translateY(.1rem),(md:translateY(10px)));
          @include res(font-size, .22rem,(md:16px));
        }
        .common-btn{
          opacity: 0;
          transition: all .3s;
          @include res(transform,translateY(.1rem),(md:translateY(10px)));
          @include res(margin-top,.4rem,(md:20px));
          &:hover{
            .icon{
              background-color: transparent;
              .iconfont{
                color: #fff;
              }
            }
          }
          .icon{
            background-color: $color-main;
            &::after{
              @include res(background-color,$color-main,(md:#fff));
            }
            // .iconfont{
            //   @include res(color,$color-main);
            // }
          }
          .t{
            @include res(width,1.15rem,(md:81px));
          }
        }
      }
    }
  }
  .item-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(padding-left,.35rem,(md:15px));
    @include res(padding-right,.35rem,(md:15px));
    @include res(padding-top,.45rem,(md:10px));
    @include res(padding-bottom,.45rem,(md:10px));
    .title{
      flex-grow: 1;
      color: #000;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include res(font-size,.3rem,(md:20px));
      &::after{
        content: '';
        display: block;
        background-color: #181d24;
        flex-grow: 1;
        @include res(height,.01rem,(md:1px));
        @include res(margin-left,.1rem,(md:5px));
        @include res(margin-right,.1rem,(md:5px));
      }
    }
    .icon{
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      img{
        display: block;
        width: auto;
        @include res(height,.3rem,(md:20px,sm:20px));
        &:not(:last-child){
          @include res(margin-right,.1rem,(md:5px));
        }
      }
    }
  }
}

//服务公共样式
.common-service{
  .service-title{
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    @include res(display,flex);
    @include res(border-top,1px solid #000);
    @include res(padding-top, 10px, 5 / 10);
    @include res(padding-bottom, 10px, 5 / 10);
    @include res(margin-bottom,100px, 20 / 100);
    h2{
      color: #000;
      font-weight: bold;
      @include res(font-size,22px, 18 / 22);
    }
    p{
      color: $color-main-light;
      @include res(font-size, 32px, 20 / 32);
    }
  }
  .service-flex{
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    .left{
      flex-shrink: 0;
      @include res(width, 44%,(sm:100%));
    }
    .right{
      flex-shrink: 0;
      flex-direction: column-reverse;
      @include res(display,null,(sm:flex));
      @include res(margin-top,0,(sm:30px,xs:20px));
      @include res(width, 49%,(sm:100%));
      .desc{
        color: #000;
        @include res(font-size, 18px, 16 / 18);
        @include res(margin-bottom, 35px,(sm:0));
        @include res(margin-top, 0px,(sm:30px,xs:20px));
      }
      .swiper{
        position: relative;
        .swiper-button-next,.swiper-button-prev{
          border: 1px solid #fff;
          border-radius: 50%;
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;
          @include res(width, 44px, 36 / 44);
          @include res(height, 44px, 36 / 44);
          &::after{
            
            font-family: 'iconfont';
            color: #fff;
            font-weight: bold;
            transform-origin: center;
            transform: scale(0.8);
            @include res(font-size, 16px, 12 / 16);
          }
        }
        .swiper-button-next{
          &::after{
            content: '\e8d4';
          }
        }
        .swiper-button-prev{
          &::after{
            content: '\eaf3';
          }
        }
      }
    }
  }
}