@import "../../assets/styles/utils";

header{
  .header{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    transition: $anime-bezier $anime-duration;
    border-bottom: .01rem solid #cccccc;
    background-color: #fff;
    @include res(height,$header-height-base,$header-height-ratio);
    @include res(box-shadow,null,(md:0 0 .15rem rgba(0,0,0,.1)));
    &.scroll{
      box-shadow: 0 0 .15rem rgba(0,0,0,.1);
      // @include res(height,.8rem,(md:1rem));
      .header-logo img{
        // @include res(height,.55rem);
      }
      .header-nav ul li .nav-title a{
        color: #333 !important;
      }
      .header-right{
        .search{
          color: #333 !important;
          &::after{
            background-color: rgba(0,0,0,.3) !important;
          }
        }
        .language{
          &::after{
            border-color: transparent transparent rgba(0,0,0,.04) transparent;
          }
          .language-title{
            color: #333 !important;
          }
        }
      }
    }
    &.trans{
      transform: translateY(-100%);
    }
    .header-logo{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include res(left,.6rem,(xs:.25rem));
      img{
        display: block;
        width: auto;
        transition: $anime-bezier $anime-duration;
        @include res(height,.76rem);
      }
    }
    .header-nav{
      height: 100%;
      @include res(display,block,(md:none));
      ul{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        li{
          height: 100%;
          &:hover{
            .nav-title a{
              span::after{
                transform: scaleX(1);
              }
            }
          }
          &.active{
            .nav-title a{
              color: $color-main-light !important;
              span::after{
                transform: scaleX(1);
              }
            }
          }
          .nav-title{
            height: 100%;
            a{
              height: 100%;
              color: #333;
              font-weight: bold;
              transition: $anime-duration $anime-bezier;
              display: block;
              @include res(padding-left,.22rem);
              @include res(padding-right,.22rem);
              @include res(font-size,.19rem);
              span{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                &::after{
                  content: '';
                  display: block;
                  width: 100%;
                  height: .02rem;
                  background-color: $color-main;
                  position: absolute;
                  bottom: -.01rem;
                  left: 0;
                  transform-origin: center;
                  transform: scaleX(0);
                  transition: transform .4s;
                }
              }
            }
          }
        }
      }
    }
    .header-right{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      @include res(right,.8rem,(xs:.4rem));
      .search{
        position: relative;
        transition: $anime-duration $anime-bezier;
        color: #333;
        @include res(padding-right,.15rem);
        &::after{
          content: '';
          display: block;
          width: .01rem;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: $anime-duration $anime-bezier;
          @include res(background-color,rgba(0,0,0,.3));
          @include res(height,.22rem,(md:22px));
        }
        .iconfont{
          cursor: pointer;
          @include res(font-size,.3rem,(md:26px));
        }
      }
      .language{
        position: relative;
        cursor: pointer;
        @include res(padding-right,.15rem,(md:.25rem));
        @include res(padding-left,.15rem,(md:.25rem));
        &:hover{
          &::after,.sub-language{
            opacity: 1;
            visibility: visible;
          }
        }
        &::after{
          content: '';
          display: block;
          border: .08rem solid;
          border-color: transparent transparent #fff transparent;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 0;
          transition: $anime-duration $anime-bezier;
          opacity: 0;
          visibility: hidden;
        }
        .language-title{
          font-weight: bold;
          transition: $anime-duration $anime-bezier;
          color: #333;
          @include res(padding-top,.1rem);
          @include res(padding-bottom,.1rem);
        }
        .sub-language{
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          text-align: center;
          border-radius: .05rem;
          overflow: hidden;
          z-index: 1;
          transition: $anime-duration $anime-bezier;
          opacity: 0;
          visibility: hidden;
          box-shadow: 0 0 .1rem rgba(0,0,0,.1);
          a{
            display: block;
            transition: $anime-duration $anime-bezier;
            @include res(padding-left,.1rem);
            @include res(padding-right,.1rem);
            @include res(padding-top,.03rem);
            @include res(padding-bottom,.03rem);
            &:hover{
              background-color: $color-main;
              color: #fff;
            }
          }
        }
      }
      .menu-icon{
        @include res(display,none,(md:block));
      }
    }
  }
  .header-search{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 101;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: $anime-bezier $anime-duration;
    transform: translateY(-100%);
    @include res(padding-right,.8rem,(xs:0px));
    @include res(padding-left,4rem);
    @media screen and(max-width:767px) {
      padding-left: 20px;
    }
    @include res(height,$header-height-base,$header-height-ratio);
    &.active{
      transform: translateY(-.01rem);
    }
    &.scroll{
      // @include res(height,.8rem,(md:1rem));
      // .header-search-logo img{
      //   @include res(height,.55rem);
      // }
    }
    .header-search-logo{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include res(left,.6rem,(xs:.25rem));
      @include res(display,block,(xs:none));
      img{
        display: block;
        width: auto;
        transition: $anime-bezier $anime-duration;
        @include res(height,.76rem);
      }
    }
    form{
      flex-grow: 1;
      display: flex;
      align-items: center;
      input{
        flex-grow: 1;
        border: none;
        @include res(height,.5rem);
        @include res(font-size, .18rem,(md:16px));
      }
      button{
        background-color: #e4e4e4;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all .4s;
        @include res(margin-left,.1rem);
        @include res(width,.4rem,(md:40px));
        @include res(height,.4rem,(md:40px));
        .iconfont{
          @include res(font-size,.28rem,(md:26px));
        }
        &:hover{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .header-search-close{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-weight: bold;
      color: #000;
      cursor: pointer;
      margin-left: .1rem;
      @include res(width, .5rem,(md:50px));
      @include res(height,.5rem,(md:50px));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        display: inline-block;
        transition: all .4s;
      }
    }
  }
  .mob-nav{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 99;
    height: 100vh;
    overflow-x: hidden;
    background: #fff;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 1.5rem);
    @include res(padding-bottom, .5rem);
    @include res(padding-left, .5rem);
    @include res(padding-right, .5rem);
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      @include res(transform,translateX(-99.8%),(xs:translateX(-100%)));
    }
    >ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      >li{
        color: #000;
        border-bottom: .01rem solid rgba(8,80,57,.2);
        // &:hover{
        //   color: $color-main;
        // }
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          line-height: 50px;
          font-family: 'nova_bold';
          @include res(padding-left,.2rem);
          @include res(display,flex);
          @include res(font-size,22px, 19 / 22);
          a{
            flex-grow: 1;
          }
          .iconfont{
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(width,50px);
            @include res(height,50px);
            @include res(font-size,30px, 22 / 30);
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, 50px, 30 / 50);
          @include res(padding-right, 50px, 30 / 50);
          @include res(padding-bottom,30px, 20 / 30);
          a{
            display: block;
          }
          .th-nav{
            @include res(padding-left,8%);
          }
        }
      }
    }
  }
  .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 98;
    display: none;
  }
}