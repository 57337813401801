@import "../../assets/styles/utils";

.nybanner{
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  // @include res(display,flex,(md:block));
  // @media screen and(max-width:1920px) {
  //   @include res(height,100vh,(md:auto));
  // }
  &::before,&::after{
    content: '';
    display: block;
    width: 100%;
    @include res(height,$header-height-base,$header-height-ratio);
  }
  &::after{
    @include res(height,$header-height-base,$header-height-ratio);
    @media screen and(max-width:1024px) {
      @include res(height,70px, 40 / 70);
    }
  }
  .img-cont{
    width: 100%;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    @include res(padding-bottom, 1.15rem,(md:50px));
    @include res(padding-top, 1.15rem,(md:50px));
    // @include res(height,600px);
    // @media screen and(max-width:1920px) {
    //   @include res(height,null,(md:400px,xs:200px));
    // }
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .bg-img{
      display: block;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    .text{
      width: 100%;
      position: relative;
      left: 0;
      z-index: 3;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      perspective: 900px;
      overflow: hidden;
      @include res(padding-left,7.8%);
      @include res(padding-right,7.8%);
      h1{
        font-weight: bold;
        line-height: 1.2;
        flex-shrink: 0;
        @include res(font-size,.56rem,(md:32px));
      }
      .line{
        flex-grow: 1;
        background-color: rgba(255,255,255,.4);
        @include res(margin-left,.3rem,(md:15px));
        @include res(margin-right,.3rem,(md:15px));
        @include res(height,.01rem,(md:1px));
      }
      .icon{
        border-radius: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255,255,255,.8);
        cursor: pointer;
        position: relative;
        @include res(border,.01rem solid rgba(255,255,255,.8), (md:1px solid rgba(255,255,255,.8)));
        @include res(width,.6rem,(md:40px));
        @include res(height,.6rem,(md:40px));
        &:hover{
          border: 1px solid rgba(255,255,255,1);
          &::after{
            transform: scale(1);
          }
          .iconfont{
            color: #000;
          }
        }
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          border-radius: .5rem;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          transform-origin: center;
          z-index: -1;
          transition: $anime-duration $anime-bezier;
          @include res(transform,scale(0));
        }
        .iconfont{
          display: block;
          transition: $anime-duration $anime-bezier;
          @include res(color,#fff);
          @include res(font-size,.22rem,(md:20px));
        }
      }
    }
  }
}

@keyframes piaofu {
  0%{
    transform: translateY(0);
  }
  33%{
    transform: translateY(-5%);
  }
  66%{
    transform: translateY(10%);
  }
  100%{
    transform: translateY(0);
  }
}