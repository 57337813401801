.pages, #page ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

@media (max-width: 1024px) {
  .pages, #page ul {
    margin-top: 20px;
  }
}

.pages a, .pages li, #page ul a, #page ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f2f2f3;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  cursor: pointer;
  transition: all .4s;
  margin-bottom: 0.05rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  font-size: 0.18rem;
  width: 0.5rem;
  height: 0.5rem;
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-left: 5px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-right: 5px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 40px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 40px;
  }
}

.pages a:hover, .pages li:hover, #page ul a:hover, #page ul li:hover {
  background-color: #ebebeb;
  color: #092f60;
}

.pages a.active, .pages a.xl-active, .pages li.active, .pages li.xl-active, #page ul a.active, #page ul a.xl-active, #page ul li.active, #page ul li.xl-active {
  background-color: #1e2e53;
  color: #fff;
}

.pages a.xl-prevPage, .pages a.xl-nextPage, .pages li.xl-prevPage, .pages li.xl-nextPage, #page ul a.xl-prevPage, #page ul a.xl-nextPage, #page ul li.xl-prevPage, #page ul li.xl-nextPage {
  display: none;
}

.pages .page_jump_text, #page ul .page_jump_text {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

@media (max-width: 1024px) {
  .pages .page_jump_text, #page ul .page_jump_text {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .pages .page_jump_text, #page ul .page_jump_text {
    margin-right: 10px;
  }
}

.pages .page_jump_input, #page ul .page_jump_input {
  width: 0.6rem;
  height: 0.3rem;
}

@media (max-width: 1024px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 60px;
  }
}

@media (max-width: 1024px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 30px;
  }
}

.pages .page_jump_btn, #page ul .page_jump_btn {
  background: none;
  border: none;
  cursor: pointer;
  transition: all;
}

.pages .page_jump_btn:hover, #page ul .page_jump_btn:hover {
  color: #092f60;
}
